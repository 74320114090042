<template>
  <div class="hero-bg">
    <section>
      <h2 class="pb-5 lead">Contest</h2>
      <span>
      Do you have Ideas for how to make AfCFTA work better? Share them here and secure investment!
      </span>
    </section>
  </div>
</template>

<script>
export default {};
</script>

<style  scoped>
.hero-bg {
  background: url("../../../assets/new/Debates.jpg");
  background-size: cover;
  background-position: center;
  padding-top: 350px;
  background-repeat: no-repeat;
  position: relative;
}

section{
    display: flex;
    flex-direction: column;
    color: #fff;
    justify-content: center;
    align-items: center;
    padding-bottom: 2em;
}
.lead{
  font-weight: bolder;
  font-size: 3em;
}
span{
  font-weight: bold;
}
</style>